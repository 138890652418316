$(document).ready(function ($) {

	$('.iframe-wrapper-play').on('click', function(e) {
		const iframe = $(this).find('iframe'),
			  wrapper = $(this);

		wrapper.unbind('click');
		wrapper.addClass('active');
		if(iframe.length) {
			const src = iframe.attr('src');
			iframe.attr('src', src + '?autoplay=1');
		}
	});

	/**********************************************
	 RESIZE END / SCROLL END
	 **********************************************/
	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}