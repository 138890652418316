jQuery(function(){
	// mobile navigation
	const body = $('body'),
		menuTrigger = $('.menu-trigger');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	// sticky navigation
	const header = document.querySelector('.header');
	if(header != null) {
		const height = header.offsetHeight;
		stickyNavigation(header, height);

		window.addEventListener('scroll', function() {
			stickyNavigation(header, height);
		});
	}
 });

 var lastScrollTop = 0;

 function stickyNavigation(header, headerHeight) {
	const st = window.pageYOffset || document.documentElement.scrollTop;
	if (st > lastScrollTop && (window.scrollY >= headerHeight)) {
		header.classList.add('header-scrolled');
	} else {
		header.classList.remove('header-scrolled');
	}
	lastScrollTop = st <= 0 ? 0 : st;
  }