var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Gallery slider
		$('.gallery-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				teaserSlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});
	}

});
